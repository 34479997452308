<template>
  <validation-observer
    ref="mixExamModalRef"
    #default="{invalid}"
  >
    <b-modal
      id="mixExamModal"
      body-class="position-static"
      centered
      title="Trộn đề thi"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label-for="qbCriteriaMixExamId"
            >
              <template v-slot:label>
                Tiêu chí trộn đề thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tiêu chí trộn đề thi"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.qbCriteriaMixExamId"
                  placeholder="Tiêu chí trộn đề thi"
                  :options="qbCriteriaMixExamsSelect"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <b-form-checkbox
              v-model="targetDataSource.isCreateExam"
              value="1"
              unchecked-value="0"
              @input="handleChecked"
            >
              Trộn tạo ra đề thi mới
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group label-for="num" v-if="checkCreateExam">
              <template v-slot:label>
                Số lượng đề mới tạo ra <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số lượng đề mới tạo ra"
                rules="required|positive"
              >
                <b-form-input
                  id="num"
                  v-model="targetDataSource.num"
                  name="num"
                  type="number"
                  placeholder="Số lượng đề mới tạo ra"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Trộn đề thi
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('mixExamModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BRow, BCol, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, positive } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import Cleave from 'vue-cleave-component'
export default {
  name: 'Save',
  components: {
    BFormInput,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    vSelect,
    BCol,
    BRow,
    ValidationProvider,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataForm: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetDataSource: {
        qbExamId: null,
        qbCriteriaMixExamId: null,
        isCreateExam: 0,
        num: 1,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 1000,
        code: null,
        name: null,
        status: null,
      },
      required,
      positive,
      checkCreateExam: false,
      qbCriteriaMixExamsSelect: [],
    }
  },
  computed: {
    ...mapGetters({
      qbCriteriaMixExams: 'qbCriteriaMixExam/dataSources',
    }),
    statuses() {
      return STATUSES
    },
  },
  methods: {
    ...mapActions({
      createMixExam: 'qbCriteriaMixExam/createMixExam',
      getCriteriaMixExams: 'qbCriteriaMixExam/getDataSources',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async handleChecked() {
      this.checkCreateExam = this.targetDataSource.isCreateExam == 1
    },
    async onShow() {
      try {
        if (this.dataForm) {
          this.targetDataSource.qbExamId = this.dataForm.id
        }
        await this.getCriteriaMixExams(this.filter)
        this.qbCriteriaMixExamsSelect = this.qbCriteriaMixExams.map(
          item => ({
            value: item.id,
            label: item.name,
          }),
        )
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .mixExamModalRef
        .reset()
      this.targetDataSource = {
        qbExamId: null,
        qbCriteriaMixExamId: null,
        isCreateExam: 0,
        num: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .mixExamModalRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.createMixExam(this.targetDataSource)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('mixExamModal')
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
