<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { ApiCode } from '@/const/api'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'ImportQuestions',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    ToastificationContent,
  },
  props: {
    qbExamId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      user: getUser(),
      itemsBC: [
        {
          text: 'Admin',
          href: '#',
        },
        {
          text: 'QbExamQuestion Management',
          href: '#',
        },
        {
          text: 'Import QbExamQuestion',
          active: true,
        },
      ],
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'name', label: 'Tên câu hỏi (*)', sortable: false, type: 'string',
          },
          {
            field: 'qbQuestionTypeCode', label: 'Loại câu hỏi(*)', sortable: false, type: 'string',
          },
          {
            field: 'qbQuestionScaleCode', label: 'Mức độ câu hỏi(*)', sortable: false, type: 'string',
          },
          {
            field: 'questionContent', label: 'Nội dung câu hỏi(*)', sortable: false, type: 'string',
          },
          {
            field: 'questionScore', label: 'Điểm câu hỏi(*)', sortable: false, type: 'string',
          },
          {
            field: 'answerContent', label: 'Nội dung câu trả lời(*)', sortable: false, type: 'string',
          },
          {
            field: 'answerScore', label: 'Điểm câu trả lời', sortable: false, type: 'string',
          },
          {
            field: 'multipleAnswerContent', label: 'Nội dung đáp án(*)', sortable: false, type: 'string',
          },
          {
            field: 'multipleAnswerScore', label: 'Điểm đáp án', sortable: false, type: 'string',
          },
          {
            field: 'isCorrect', label: 'Đáp án đúng', sortable: false, type: 'string',
          },
          {
            field: 'multiple', label: 'Câu hỏi nhiều đáp án', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
      selected: '',
      numberConfig: 1,
      distinctClassCode: [],
      valueSetup: 0,
    }
  },
  computed: {
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.QB_EXAM_QUESTION)
    },
  },
  methods: {
    ...mapActions({
      importQuestion: 'qbExams/importQuestions',
    }),
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },
    
    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read( data, {
            type: 'binary',
          } )
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json( workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          } )
          const excellist = []
          // Edit data
          if ( ws.length > 0 ) {
            for ( let i = 0; i < ws.length; i++ ) {
              excellist.push( ws[i] )
            }
          } else {
            this.showToast( 'File không có dữ liệu', 'XCircleIcon', 'danger', '' )
            return
          }
          if (
            !excellist[0].hasOwnProperty( 'TEN_CAU_HOI' )
            || !excellist[0].hasOwnProperty( 'LOAI_CAU_HOI_CODE' )
            || !excellist[0].hasOwnProperty( 'MUC_DO_CAU_HOI_CODE' )
            || !excellist[0].hasOwnProperty( 'NOI_DUNG_CAU_HOI' )
            || !excellist[0].hasOwnProperty( 'DIEM_CHO_CAU_HOI' )
            || !excellist[0].hasOwnProperty( 'TEN_DAP_AN_TRA_LOI' )
            || !excellist[0].hasOwnProperty( 'NOI_DUNG_DAP_AN_TRA_LOI' )
            || !excellist[0].hasOwnProperty( 'DIEM_CHO_DAP_AN_TRA_LOI' )
            || !excellist[0].hasOwnProperty( 'DAP_AN_DUNG' )
            || !excellist[0].hasOwnProperty( 'CO_NHIEU_DAP_AN' )
          ) {
            this.showToast( 'File chưa đúng định dạng', 'XCircleIcon', 'danger', '' )
            return
          }
          
          this.dataExcel = []
          for ( let k = 0; k < excellist.length; k++ ) {
            const item = excellist[k]
            const obj = {
              stt: k + 1,
              name: item.TEN_CAU_HOI,
              qbQuestionTypeCode: item.LOAI_CAU_HOI_CODE,
              qbQuestionScaleCode: item.MUC_DO_CAU_HOI_CODE,
              questionContent: item.NOI_DUNG_CAU_HOI,
              questionScore: item.DIEM_CHO_CAU_HOI,
              orderNo: '',
              answerName: item.TEN_CAU_TRA_LOI,
              answerContent: item.NOI_DUNG_CAU_TRA_LOI,
              answerScore: item.DIEM_CHO_CAU_TRA_LOI,
              multipleAnswerName: item.TEN_DAP_AN_TRA_LOI,
              multipleAnswerContent: item.NOI_DUNG_DAP_AN_TRA_LOI,
              multipleAnswerScore: item.DIEM_CHO_DAP_AN_TRA_LOI,
              isCorrect: item.DAP_AN_DUNG,
              multiple: item.CO_NHIEU_DAP_AN,
              status: 1,
              delFlag: 0,
            };
            
            this.dataExcel.push( obj )
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length
          if ( this.totalRecords > 10 ) {
            this.table.items = this.dataExcel.slice( 0, 10 )
          }
        } catch ( e ) {
          return alert( `Read failure!${ e }` )
        }
      }
      fileReader.readAsBinaryString( files[0] )
    },
    
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    
    async importExamQuestion() {
      this.loading = true
      try {
        const res = await this.importQuestion({
          id: this.qbExamId,
          params: this.dataExcel,
        })
        if (res.isSuccessful) {
          this.showToast( 'Lưu thành công', 'CheckIcon', 'success', res.message )
          this.resetInputFile()
        }
      } catch ( e ) {
        this.showToast( 'Có lỗi xảy ra', 'XCircleIcon', 'danger', e.message )
      } finally {
        this.loading = false
      }
    },
    downloadFileTN() {
      const item = { index: 11, width: 100 }
      const columns = [
        { index: 1, width: 230 },
        { index: 2, width: 230 },
        { index: 3, width: 230 },
        { index: 4, width: 230 },
        { index: 5, width: 230 },
        { index: 6, width: 230 },
        { index: 7, width: 230 },
        { index: 8, width: 230 },
        { index: 9, width: 230 },
        { index: 10, width: 230 },
      ]
      
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'TEN_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'LOAI_CAU_HOI_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'MUC_DO_CAU_HOI_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'NOI_DUNG_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'DIEM_CHO_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'TEN_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'NOI_DUNG_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'DIEM_CHO_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'DAP_AN_DUNG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'CO_NHIEU_DAP_AN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      if ( this.selected ) {
        columns.push( item )
      }
      headerRow.cells.push()
      rows.push( headerRow )
      const worksheets = [ { columns, rows } ]
      const workbook = new Workbook( { worksheets }, 'xlsx' )
      workbook.save( 'import_CauHoiTracNghiemDeThi.xlsx' )
    },
    downloadFile() {
      const item = { index: 18, width: 100 }
      const columns = [
        { index: 1, width: 230 },
        { index: 2, width: 230 },
        { index: 3, width: 230 },
        { index: 4, width: 230 },
        { index: 5, width: 230 },
        { index: 6, width: 230 },
        { index: 7, width: 230 },
        { index: 8, width: 230 },
        { index: 9, width: 230 },
        { index: 10, width: 230 },
        { index: 11, width: 230 },
        { index: 12, width: 230 },
        { index: 13, width: 230 },
      ]
      
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'TEN_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'LOAI_CAU_HOI_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'MUC_DO_CAU_HOI_CODE',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'NOI_DUNG_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'DIEM_CHO_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'TEN_CAU_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'NOI_DUNG_CAU_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'DIEM_CHO_CAU_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'TEN_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'NOI_DUNG_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'DIEM_CHO_DAP_AN_TRA_LOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'DAP_AN_DUNG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'CO_NHIEU_DAP_AN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      
      if ( this.selected ) {
        columns.push( item )
      }
      headerRow.cells.push()
      rows.push( headerRow )
      const worksheets = [ { columns, rows } ]
      const workbook = new Workbook( { worksheets }, 'xlsx' )
      workbook.save( 'import_CauHoiDeThi.xlsx' )
    },
    
    showToast( title, icon, variant, text ) {
      this.$toast( {
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      },
      )
    },
    onPageChange( params ) {
      const start = params.currentPerPage * ( params.currentPage - 1 )
      const end = this.dataExcel.length > ( params.currentPerPage * params.currentPage ) ? ( params.currentPerPage * params.currentPage ) : this.dataExcel.length
      this.table.items = this.dataExcel.slice( start, end )
    },
    onPerPageChange( params ) {
      const start = params.currentPerPage * ( params.currentPage - 1 )
      const end = this.dataExcel.length > ( params.currentPerPage * params.currentPage ) ? ( params.currentPerPage * params.currentPage ) : this.dataExcel.length
      this.table.items = this.dataExcel.slice( start, end )
    },
  },
}
</script>

<style type="text/css">
.form_file {
  width: auto !important;
}
</style>
