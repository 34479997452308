<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'statusTemp'"
                      :class="props.row.statusTemp !== -1 ? 'text-danger':'text-success font-weight-bolder'"
                      v-html="getIsCorrect(props.row.statusTemp)"
                    />
                    <span v-else-if="props.column.field === 'status'">
                      {{ props.row.status === 1 ? 'Bản dự thảo' : 'Bản dự thảo tạo từ trộn đề' }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-dropdown right variant="success" no-caret size="sm">
                        <template #button-content>
                          <feather-icon
                            icon="MoreHorizontalIcon"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          variant="info"
                          class="btn-icon"
                          size="sm"
                          @click="listAnswers(props.row)"
                        >
                          <feather-icon icon="ListIcon"/> Danh sách câu hỏi
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="updatable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          @click="onUpdateStatus(props.row)"
                        >
                          <feather-icon icon="EditIcon"/> Duyệt đề thi
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          @click="onDelete(props.row)"
                        >
                          <feather-icon icon="TrashIcon"/> Xóa đề thi
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      ref="modal-exam_questions"
      id="modal-exam_questions"
      centered
      size="xl"
      :title="`Danh sách câu hỏi cho đề thi: ${dataSendClass.name}`"
      :hide-footer=true
      no-close-on-backdrop
    >
      <ExamQuestions :qb-exam-id="dataSendClass.id"/>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import ExamQuestions from '@/views/exam-question/qb-exam/ExamQuestions.vue'
import { QB_EXAM_STATUSES, MIX_EXAMS } from '@/const/status'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    ExamQuestions,
    BDropdownItem,
    BDropdown,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    qbExamId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        originalQbExamQuestionId: null,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      dataSendClass: {},
      countExam: 1,
      qbExamStructures: [],
      checkShow: false,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'qbExams/dataSourcesById',
      totalRows: 'qbExams/totalRowsById',
    }),
    columns() {
      return [
        {
          label: 'Tên đề thi',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học phần',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.QB_QUESTION)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.QB_QUESTION)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.QB_QUESTION)
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapMutations({
      setDataSources: 'qbExams/SET_DATA_SOURCES_BY_ID',
    }),
    ...mapActions({
      getDataSources: 'qbExams/getDataSourcesById',
      deleteDataSource: 'qbExams/deleteDataSource',
    }),
    getIsCorrect(id) {
      return MIX_EXAMS.find(status => status.value === id).label
    },
    async onLoad() {
      this.isLoading = true
      try {
        await this.getDataSourcesFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    listAnswers(data) {
      this.dataSendClass = data
      this.$refs['modal-exam_questions'].show()
    },
    onDelete(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa đề thi <span class="text-danger">${dataSource.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDataSource(dataSource.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onUpdateStatus(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn duyệt đề thi <span class="text-danger">${dataSource.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            // eslint-disable-next-line no-param-reassign
            dataSource.status = 2
            const response = await this.updateDataSource(dataSource)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        this.filter.originalQbExamQuestionId = this.qbExamId
        await this.getDataSources(this.filter)
        this.checkShow = this.dataSources.length <= 0
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceed(subjectId) {
      this.filter.subjectId = subjectId
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return QB_EXAM_STATUSES.find(status => status.value === id).label
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
